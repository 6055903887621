<template>
    <div :class="deviceType == 'pc'?'case':'case-m'">
        <div class="banner flex-column">
            <h1>客户案例</h1>
            <h3>互联网技术行业最热解决方案</h3>
        </div>
        <div class="module1 flex-column">
            <div class="module1-list flex-row">
                <div v-for="(item,index) in moduleList1" :key="'module1' + index" class="module1-item flex-column" @click="navTo('caseDetails',{index:index})">
                    <h3>{{item.title}}</h3>
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            moduleList1: [
                {
                    img: require('../assets/case08.png'),
                    title: '三合壹会员积分平台H5',
                },
                {
                    img: require('../assets/case09.png'),
                    title: '伊米阳光商城小程序',
                },
                {
                    img: require('../assets/case15.png'),
                    title: '调味帮',
                },
                {
                    img: require('../assets/case01.jpg'),
                    title: '可悦社交APP',
                },
                {
                    img: require('../assets/case06.png'),
                    title: '飞创E家小程序',
                },
                {
                    img: require('../assets/case05.png'),
                    title: '嘀家智行小程序',
                },
                {
                    img: require('../assets/case07.png'),
                    title: '丙星OA智慧办公APP',
                },
                {
                    img: require('../assets/case16.png'),
                    title: '美点充电小程序',
                },
                {
                    img: require('../assets/case17.png'),
                    title: '唯知教育',
                },
                // {
                //     img: require('../assets/case02.png'),
                //     title: '智慧社区服务大数据系统',
                // },
                {
                    img: require('../assets/case03.png'),
                    title: '三合壹会员积分系统',
                },
                {
                    img: require('../assets/case04.png'),
                    title: '邮件管理系统',
                },
                {
                    img: require('../assets/case10.png'),
                    title: '消防线上考试培训',
                },
                {
                    img: require('../assets/case11.png'),
                    title: '熊猫e家物业管理系统',
                },
                // {
                //     img: require('../assets/case12.png'),
                //     title: '智慧公安系统',
                // },
                // {
                //     img: require('../assets/case13.png'),
                //     title: '智慧城市管理数据分析',
                // },
                // {
                //     img: require('../assets/case14.png'),
                //     title: '园区运行态势数据展示平台',
                // },
            ],
        }
    }
}
</script>

<style lang="less" scoped>
.case {
    padding: 80px 0;
    .banner {
        width: 100vw;
        min-width: 600px;
        height: 400px;
        position: relative;
        overflow: hidden;
        background: url('../assets/case_banner.jpg') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: flex-start;
        h1 {
            font-size: 48px;
            margin-left: 120px;
        }
        h3 {
            font-size: 36px;
            margin-left: 120px;
        }
    }
    .module1 {
        margin: 120px 0;
        .module1-list {
            width: 1400px;
            flex-wrap: wrap;
            justify-content: flex-start;
            .module1-item {
                width: 400px;
                height: 500px;
                font-size: 14px;
                margin: 32px;
                cursor: pointer;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0 0 8px #cccccc;
                img {
                    width: 400px;
                    height: 400px;
                    object-fit: contain;
                }
                h3 {
                    margin: 16px 0 0 0;
                    font-size: 28px;
                }
            }
        }
    }
}
.case-m {
    width: 100%;
    padding: 60px 0;
    h1 {
        font-size: 24px;
        margin: 20px 0;
        font-weight: bolder;
    }
    h3 {
        font-size: 18px;
        margin: 8px 0;
    }
    p {
        font-size: 14px;
        text-align: start;
        text-indent: 28px;
    }
    .banner {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        background: url('../assets/case_banner.jpg') center no-repeat;
        background-size: cover;
        color: rgb(255, 255, 255);
        justify-content: center;
        align-items: center;
    }
    .module1-list {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        // margin-top: 60px;
        .module1-item {
            width: 46%;
            height: 260px;
            font-size: 14px;
            margin: 16px auto;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 0 4px #cccccc;
            img {
                width: 100%;
                height: 200px;
                object-fit: contain;
            }
            h3 {
                margin: 16px 0 0 0;
            }
        }
    }
}
</style>